import Vue from 'vue';
Vue.mixin({
    computed: {
        user () {
            return this.$store.getters["auth/user"] || {};
        },
        isAuthenticated () {
            return this.$store.getters["auth/isAuthenticated"];
        }
    },
    methods: {
        logout () {
            this.$store.dispatch('auth/logout');
            this.$router.push('/');
        }
    }
});
