import Vue from 'vue';
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const MainLayout = () => import('@/layouts/Main');

const Dmarc = () => import('@/views/Dmarc')
const Home = () => import('@/views/Home')
const Login = () => import('@/views/Login')
const SignUp = () => import('@/views/SignUp')
const Share = () => import('@/views/Share')
const Template = () => import('@/views/Template')
const requiresAuth = true;
const routes = [
	{
		path: '',
		component: MainLayout,
		children: [
			{ path: '/', component: Home },
			{ path: '/login', component: Login },
			{ path: '/create-account', component: SignUp },
			{ path: '/dmarc', component: Dmarc, meta: { requiresAuth } },
			{ path: '/template', component: Template, meta: { requiresAuth } }
		],
	},
	{ path: '/share', component: Share },
];

const router = new VueRouter({
	mode: 'history',
	routes // short for `routes: routes`
});

const guards = (router, store) => {
	router.beforeEach((to, from, next) => {
		const isAuthenticated = store.getters["auth/isAuthenticated"];
		const isAdmin = store.getters["auth/isAdmin"];
		const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
		const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

		if (requiresAuth && !isAuthenticated) {
			next('/login');
		} else if (requiresAdmin && !isAdmin) {
			next('/');
		} else {
			next();
		}
	});
}

export { guards, routes, router }
