<template>
	<router-view></router-view>
</template>

<script>
export default {
	name: 'App'
}
</script>

<style lang="scss">
	@import '@/assets/css/app.scss';
	@import 'bootstrap/scss/bootstrap';
	@import 'bootstrap-vue/src/index.scss';
</style>
