import Vue from 'vue'

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import store from '@/modules/store';
import { router, guards } from '@/modules/router';
guards(router, store);
import '@/modules/mixins';

Vue.config.productionTip = false

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-J4454WTH8G" },
}, router);

import App from './App.vue'
store.dispatch('auth/authenticate')
.catch(() => {}).then(() => {
	new Vue({
		render: h => h(App),
		router,
		store
	}).$mount('#app')
});
