import { makeAuthPlugin } from '../feathers-client'

export default makeAuthPlugin({
	userService: 'users',
	getters: {
		isAdmin (state, getters) {
			const { user, isAuthenticated } = getters;
			return (isAuthenticated && user.roles && user.roles.indexOf('admin') > -1);
		}
	}
})
